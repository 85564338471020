@tailwind base;
@tailwind components;
@tailwind utilities;

/* Height & Width */
::-webkit-scrollbar {
  display: none;
  /* height: 6px;
  width: 6px; */
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 2px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 2px;
}
